import competitorLists from '../../Assets/managefeisGrade/competitorList.svg';
import addCompetitor from '../../Assets/managefeisGrade/addCompetitor.svg';
import editCompetitor from '../../Assets/managefeisGrade/editCompetitor.svg';
import directLink from '../../Assets/managefeisGrade/directLink.svg';
import email from '../../Assets/managefeisGrade/email.svg';
import eventDetails from '../../Assets/managefeisGrade/eventDetails.svg';
import accommodation from '../../Assets/managefeisGrade/accommodation.svg';
import sponsors from '../../Assets/managefeisGrade/sponsors.svg';
import scheduleMaker from '../../Assets/managefeisGrade/schedulemaker.svg';
import uploadComp from '../../Assets/managefeisGrade/uploadSchedule.svg';
import downloadComp from '../../Assets/managefeisGrade/downloadCompetitions.svg';
import examiners from '../../Assets/managefeisGrade/examiners.svg';
import syllabus from '../../Assets/managefeisGrade/syllabus.svg';

export const manageGradeArray = [
  {
    mainTitle: 'Entries',
    details: [
      {
        logo: competitorLists,
        title: 'Candidate Lists',
        description: 'Full list of registered competitors',
        path: '/candidate',
        id: true,
        isModal: false,
        name: true,
        cardClickEnabled: true,
      },
      {
        logo: addCompetitor,
        title: 'Add a Candidate',
        description: 'Create a new entry',
        isModal: true,
        modal: 'AddCompetitor',
        // cardClickEnabled: true,
      },

      {
        logo: editCompetitor,
        title: 'Edit Candidates',
        description: 'Add / Change an existing Entry',
        isModal: false,
        path: '/edit-candidates',
        name: true,
        id: true,
        // cardClickEnabled: true,
      },
      {
        logo: directLink,
        title: 'Direct Link',
        description: 'Create a Link for Late Entries',
        isModal: true,
        modal: 'DirectLink',
        // cardClickEnabled: true,
      },
      {
        logo: email,
        title: 'Email',
        description: 'Send an email to all registered competitors',
        isModal: false,
        path: '/email',
        id: true,
        cardClickEnabled: true,
        from: 'grade',
      },
    ],
  },
  {
    mainTitle: 'Event',
    details: [
      {
        logo: eventDetails,
        title: 'Event Details',
        description: 'Location, Date, etc.',
        isModal: false,
        path: '/event-details',
        // name: true,
        id: true,
        cardClickEnabled: true,
      },
      {
        logo: accommodation,
        title: 'Accommodation',
        description: 'Add or Edit Hotel Info',
        onClick: '',
        isModal: true,
        modal: 'Accomodation',
        cardClickEnabled: true,
      },
      {
        logo: syllabus,
        title: 'Syllabus',
        description: 'Make Changes to the Syllabus',
        isModal: false,
        path: '/grade/create',
        tab: 5,
        // cardClickEnabled: true,
      },
      {
        logo: examiners,
        title: 'Examiners',
        description: 'Add or Edit Examiners',
        onClick: '',
        isModal: true,
        modal: 'Examiners',
        cardClickEnabled: true,
      },
      {
        logo: sponsors,
        title: 'Sponsors',
        description: 'Add or Edit Sponsors',
        isModal: true,
        modal: 'Sponsors',
        cardClickEnabled: true,
      },
    ],
  },
  {
    mainTitle: 'Schedule',
    details: [
      {
        logo: uploadComp,
        title: 'Upload Schedule',
        description: 'Upload PDF Schedule',
        onClick: '',
        // cardClickEnabled: true,
        upload: true,
      },
      {
        logo: downloadComp,
        title: 'Download Candidates',
        description: 'Export a CSV of all candidates',
        isModal: false,
        downloadCsv: true,
        cardClickEnabled: true,
      },
    ],
  },
  {
    mainTitle: 'Financial',
    details: [
      {
        logo: scheduleMaker,
        title: 'Stripe Account',
        description: 'Stripe Account',
        isModal: false,
        path: '/stripe-account',
        name: false,
        id: true,
        cardClickEnabled: true,
      },
      {
        logo: uploadComp,
        title: 'Fees',
        description: 'View / Edit Family and Late Fees',
        isModal: false,
        path: '/organizer-create-grade',
        tab: 4,
        // cardClickEnabled: true,
      },
      {
        logo: uploadComp,
        title: 'Transactions',
        description: 'View Transactions',
        isModal: false,
        path: '/transaction-history',
        name: true,
        id: true,
        cardClickEnabled: true,
      },
    ],
  },
];
